import { isAuthenticate } from '@/utils/jwt-operations'

export const navigationHelper = (userRole = 0, routes) => {
  const result = []
  for (const route of routes) {
    if (route.children?.length > 0) {
      result.push(...navigationHelper(userRole, route.children))
      continue
    }

    if (!route?.meta) {
      continue
    }

    if (!route.meta.showSidebar) {
      continue
    }

    if (route.meta.authRequire && !isAuthenticate()) {
      continue
    }

    if (route.meta.roles?.length === 0) {
      result.push({
        path: route.path,
        name: route.name,
        icon: route.meta.icon,
        label: route.meta.label
      })
      continue
    }

    if (!userRole || !route.meta.roles?.includes(userRole)) {
      continue
    }

    result.push({
      path: route.path,
      name: route.name,
      icon: route.meta.icon,
      label: route.meta.label
    })
  }
  return result
}
