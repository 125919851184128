import globals from '@/globals'
import { router } from '@/router'
import { getRole } from '@/utils/jwt-operations'
import store from '@/store'

router.afterEach((to, from) => {
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
    splashScreen.style.opacity = 0
    setTimeout(() => splashScreen && splashScreen.parentNode.removeChild(splashScreen), 300)
  }
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  if (to.matched?.length === 0) {
    document.body.classList.add('app-loading')
    setTimeout(() => next({
      path: '/anasayfa',
      query: { returnUrl: to.path }
    }), 10)
    return
  }

  if (to.matched[0].path === '*') {
    document.body.classList.add('app-loading')
    setTimeout(() => next(), 10)
    return
  }
  const userRole = getRole()
  const isAuthenticated = store.getters.isAuthenticate

  if (!to.meta) {
    document.body.classList.add('app-loading')
    setTimeout(() => next(), 10)
    return
  }

  if (to.meta.isLoginPage && (localStorage.getItem('access_token') || localStorage.getItem('refresh_token'))) {
    document.body.classList.add('app-loading')
    setTimeout(() => next({
      path: to.query.returnUrl || '/anasayfa'
    }), 10)
    return
  }

  if (to.meta.authRequire && !isAuthenticated) {
    document.body.classList.add('app-loading')
    setTimeout(() => next({
      path: '/giris',
      query: { returnUrl: to.path }
    }), 10)
    return
  }

  if (to.meta.roles?.length !== 0 && !to.meta.roles.includes(userRole)) {
    document.body.classList.add('app-loading')
    setTimeout(() => next({
      path: '/anasayfa',
      query: { returnUrl: to.path }
    }), 10)
    return
  }

  document.body.classList.add('app-loading')
  setTimeout(() => next(), 10)
})
