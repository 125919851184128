import axios from 'axios'
import store from '../store'
class Request {
    static isRefreshing = false
    static failedQueue = []
    static baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api/' : window.location.origin + '/api/'

    static processQueue (error, token = null) {
      this.failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error)
        } else {
          prom.resolve(token)
        }
      })

      this.failedQueue = []
    }
}

const service = axios.create(
  {
    baseURL: Request.baseUrl
  })

service.interceptors.request.use(
  async config => {
    config.headers.post['Content-Type'] = 'application/json multipart/form-data'
    if (localStorage.getItem('access_token')) {
      config.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const resultData = {
      data: response.data.data,
      data_count: response.data.data_count
    }

    return Promise.resolve(resultData)
  },
  async error => {
    if (!error?.response) {
      window.vue.showSwalError('İletişim hatası oluştu')
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(null)
    }
    const { status } = error.response
    const errorMessage = error?.response?.data?.error_message
    if (status === 401) {
      const response = await refresh(error)
      return Promise.resolve(response)
    } else if (status === 400 && errorMessage) {
      window.vue.showSwalError(errorMessage)
    } else if (status === 403) {
      if (errorMessage) {
        window.vue.showSwalError(errorMessage)
      }
      window.vue.$router.push('/anasayfa')
    } else if (status === 404) {
      window.vue.showSwalError('Bu modül henüz aktifleştirilmemiş')
    } else if (status >= 500) {
      window.vue.showSwalError('Sunucu hatası oluştu')
    } else {
      window.vue.showSwalError('Bilinmeyen bir hata oluştu')
    }
    return Promise.reject(error.response)
  }
)

const refresh = async (error) => {
  const originalRequest = error.config
  if (originalRequest._retry) {
    return Promise.reject(error)
  }
  if (Request.isRefreshing) {
    return new Promise(function (resolve, reject) {
      Request.failedQueue.push({ resolve, reject })
    })
      .then((token) => {
        originalRequest.headers.Authorization = 'Bearer ' + token
        return service(originalRequest)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  originalRequest._retry = true
  Request.isRefreshing = true
  return new Promise((resolve, reject) => {
    axios.request({
      url: `${Request.baseUrl}auth/refresh`,
      method: 'POST',
      data: {
        refresh_token: localStorage.getItem('refresh_token')
      }
    }).then((response) => {
      store.dispatch('setToken', response.data.data)
      originalRequest.headers.Authorization = 'Bearer ' + response.data.data.access_token
      Request.processQueue(null, response.data.data.access_token)
      resolve(service(originalRequest))
    }).catch((err) => {
      Request.processQueue(err, null)
      store.commit('logout')
    }).finally(() => {
      Request.isRefreshing = false
    })
  })
}

export default service
