// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import Vue from 'vue'
import App from './App'
import { router } from './router'
import '@/permission'
import { BootstrapVue, ToastPlugin } from 'bootstrap-vue'
import flatpickr from 'flatpickr'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import VueClipboard from 'vue-clipboard2'
import globals from './globals'
import Popper from 'popper.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import toast from './mixins/toast'
import copy from './mixins/copy'
import swal from './mixins/sweetalert'
import Toasted from 'vue-toasted'
import VueGoodTablePlugin from 'vue-good-table'

import moment from 'moment'
import 'moment/locale/tr'
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import tr from 'vee-validate/dist/locale/tr.json'
// eslint-disable-next-line camelcase
import { required, numeric, min, max, min_value } from 'vee-validate/dist/rules'
import Vuex from 'vuex'

import store from '@/store'
extend('required', required)
extend('numeric', numeric)
extend('min', min)
extend('max', max)
extend('min_value', min_value)
localize({ tr })
localize('tr')

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

moment.locale('tr')
Vue.use(Toasted)
Vue.use(VueClipboard)
Vue.use(VueVideoPlayer)
flatpickr.localize(Turkish)

Vue.use(VueGoodTablePlugin)
// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(VueClipboard)
Vue.use(VueSweetalert2)
// Global RTL flag
Vue.mixin({
  data: globals
})
Vue.mixin(toast)
Vue.mixin(copy)
Vue.mixin(swal)

window.vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
