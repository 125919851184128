
export default {
  methods: {
    async showSwalError (message = '') {
      await this.$swal({
        icon: 'error',
        confirmButtonText: 'Tamam',
        html: message
      })
    }
  }
}
