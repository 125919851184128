export default {
  methods: {
    async showToastError (message = '', title = 'Hata!', autoHideDelay = 5000) {
      this.$bvToast.toast(message, {
        title: title,
        variant: 'danger',
        solid: true,
        autoHideDelay
      })
    },
    async showToastSuccess (
      message = 'İşlem Başarı ile Tamamlandı',
      title = 'Başarılı!',
      autoHideDelay = 5000
    ) {
      this.$bvToast.toast(message, {
        title: title,
        variant: 'success',
        solid: true,
        autoHideDelay
      })
    },
    async showToastInfo (message, title = 'Bilgi', autoHideDelay = 5000) {
      this.$bvToast.toast(message, {
        title: title,
        variant: 'info',
        solid: true,
        autoHideDelay
      })
    }
  }
}
