import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/components/NotFound'

// Layouts
import Layout1 from '@/layout/Layout1'

Vue.use(Router)
Vue.use(Meta)

export const routes = [
  {
    path: '/giris',
    name: 'login',
    component: () => import('@/views/auth/Login'),
    meta: {
      roles: [],
      showSidebar: false,
      authRequire: false,
      isLoginPage: true
    }
  },
  {
    path: '/',
    redirect: '/anasayfa',
    component: Layout1,
    children: [{
      path: 'anasayfa',
      name: 'homepage',
      component: () => import('@/components/Home.vue'),
      meta: {
        label: 'Anasayfa',
        icon: 'fa fa-home',
        roles: [],
        showSidebar: true,
        authRequire: true
      }
    }, {
      path: 'kullanicilar',
      name: 'user-list',
      component: () => import('@/views/user/User'),
      meta: {
        label: 'Kullanıcılar',
        icon: 'fa fa-user',
        roles: [],
        showSidebar: true,
        authRequire: true
      }
    }, {
      path: 'talebeler',
      name: 'student-list',
      component: () => import('@/views/student/Student.vue'),
      meta: {
        label: 'Talebeler',
        icon: 'fa fa-address-book',
        roles: [],
        showSidebar: true,
        authRequire: true
      }
    }, {
      path: 'odeme-plani/:id',
      name: 'payment-plan',
      component: () => import('@/views/payment_plan/PaymentPlan.vue'),
      meta: {
        label: 'Ödeme Planı',
        roles: [],
        showSidebar: false,
        authRequire: true
      }
    }, {
      path: 'odeme/:id',
      name: 'payment',
      component: () => import('@/views/payment/Payment.vue'),
      meta: {
        label: 'Ödeme',
        roles: [],
        showSidebar: false,
        authRequire: true
      }
    }]
  }, {
    path: '*',
    component: NotFound
  }]

export const router = new Router({
  base: '/',
  mode: 'history',
  routes: routes
})
