import request from '@/api/request'

const state = () => ({
  isAuthenticate: localStorage.getItem('access_token') !== null,
  user: {}
})

const getters = {
  isAuthenticate: state => state.isAuthenticate,
  user: state => state.user,
  nameSurname: state => {
    if (!state.isAuthenticate) {
      return ''
    }
    return state.user.name + ' ' + state.user.surname
  }
}

const mutations = {
  setIsAuthenticate (state, value) {
    state.isAuthenticate = value
  },
  setUser (state, value) {
    state.user = value
  },
  logout (state) {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    state.isAuthenticate = false
    state.user = {}
    const redirect = window.location.pathname + window.location.search
    const urlParams = new URLSearchParams()
    urlParams.set('returnUrl', redirect)
    document.location.href = '/giris?' + urlParams.toString()
  }
}

const actions = {
  async setUser ({ context, state, commit }) {
    if (!state.isAuthenticate) {
      return
    }
    const result = await request({ url: 'user/me' })
    commit('setUser', result.data)
  },
  setToken ({ context, commit }, data) {
    localStorage.setItem('access_token', data.access_token)
    localStorage.setItem('refresh_token', data.refresh_token)
    commit('setIsAuthenticate', true)
  }

}

export default {
  state,
  mutations,
  actions,
  getters
}
