
export function parseJwt () {
  const token = localStorage.getItem('access_token')
  if (!token) {
    return {}
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

export function isAuthenticate () {
  return !!(localStorage.getItem('access_token') || localStorage.getItem('refresh_token'))
}

export function getName () {
  const parsedJWT = parseJwt()
  return parsedJWT?.name || ''
}

export function getMail () {
  const parsedJWT = parseJwt()
  return parsedJWT?.eposta || ''
}

export function getRole () {
  const parsedJWT = parseJwt()
  return parsedJWT?.yetki || 0
}

export function getID () {
  const parsedJWT = parseJwt()
  return parsedJWT?.kisi_id || 0
}

export function getIl () {
  const parsedJWT = parseJwt()
  return parsedJWT?.il_id
}

export function getBolge () {
  const parsedJWT = parseJwt()
  return parsedJWT?.bolge_id
}

export function getIlce () {
  const parsedJWT = parseJwt()
  return parsedJWT?.ilce_id
}

export function getUlke () {
  const parsedJWT = parseJwt()
  return parsedJWT?.ulke_id
}

export function getCinsiyet () {
  const parsedJWT = parseJwt()
  return parsedJWT?.cinsiyet
}
